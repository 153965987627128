import React from 'react';
import ReactDOM from 'react-dom';
import detectBrowserLanguage from 'detect-browser-language'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import locale2 from 'locale2'
import getBrowserLanguage from 'get-browser-language'

console.log(detectBrowserLanguage())
console.log(locale2)
console.log(getBrowserLanguage())

ReactDOM.render(<App language={detectBrowserLanguage() == 'it-IT' ? 'italian' : 'english'} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
