import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import PropTypes from "prop-types";
import {
  Container,
  Form,
  TextArea,
  Button,
  Divider,
  Grid,
  Header,
  Message,
  Icon,
  Step,
  Table,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";
import Alert from "react-s-alert";
import "semantic-ui-css/semantic.min.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-s-alert/dist/s-alert-css-effects/flip.css";

const PATREON_LINK = "http://patreon.com/Robin_Good";
const contents = {
  italian: {
    footerNote: "",
	button: "Formatta e copia",
	newsletterButton: "Iscriviti ora",
    description: () => (
      <p>
        Formatta i testi per Instagram, Facebook e altri social <br />
        potendo mantenere spaziature e righe vuote fra paragrafi
      </p>
    ),
    alert:
      "Il tuo testo *formattato* è ora pronto per essere incollato nel tuo social preferito.",
    instructions: {
      title: "Istruzioni",
      one: {
        title: "Scrivi",
        subtitle: () => (
          <Step.Description>
            Inserisci il contenuto del tuo post <br /> nell’apposito box e
            formattalo come desideri.
          </Step.Description>
        ),
      },
      two: {
        title: "Converti",
        subtitle: () => (
          <Step.Description>
            Clicca il bottone “Formatta & Copia” <br /> per convertire il tuo
            testo e copiarlo.
          </Step.Description>
        ),
      },
      three: {
        title: "Incolla",
        subtitle: () => (
          <Step.Description>
            Vai sul social dove <br /> intendi pubblicare e “incolla”.
          </Step.Description>
        ),
      },
	},
	newsletter: {
		description: "Diventa il consulente di fiducia nella tua nicchia di mercato"
	}
  },
  english: {
    footerNote: "",
	button: "Format and copy",
	newsletterButton: "Subscribe now",
    description: () => (
      <p>
        Format text for Instagram, Facebook and other social platforms <br />
        while maintaining spaces and empty lines between paragraphs
      </p>
    ),
    alert:
      "Your *formatted* text is now ready to be pasted in yout favorite social media.",
    instructions: {
      title: "Instructions",
      one: {
        title: "Write",
        subtitle: () => (
          <Step.Description>
            Type the contents of your post <br /> in the input box and format it
            the <br /> way you like it
          </Step.Description>
        ),
      },
      two: {
        title: "Convert",
        subtitle: () => (
          <Step.Description>
            Click the button "Format & Copy" <br /> to convert your text and to
            auto-format it
          </Step.Description>
        ),
      },
      three: {
        title: "Paste",
        subtitle: () => (
          <Step.Description>
            Go to the social platform <br /> where you want to publish <br />{" "}
            and "paste".
          </Step.Description>
        ),
      },
	},
	newsletter: {
		description: 'Become the "trusted" advisor in your market niche'
	}
  },
};

function insertAtCursor(myField, myValue) {
  //MOZILLA and others
  if (myField.selectionStart || myField.selectionStart == "0") {
    var startPos = myField.selectionStart;
    var endPos = myField.selectionEnd;
    myField.value =
      myField.value.substring(0, startPos) +
      myValue +
      myField.value.substring(endPos, myField.value.length);
  } else {
    myField.value += myValue;
  }
  myField.selectionEnd = endPos + 2;
  myField.blur();
  myField.focus();
}

function goToNewsletter() {

}

const TableCell = (props) => (
  <Table.Cell id={props.id} onClick={(e) => props.handleClick(props.id)}>
    {props.children}
  </Table.Cell>
);

// jsx-a11y/accessible-emoji
class EmoticonsGrid extends Component {
  handleClick(id) {
    var str = document.getElementById(id).innerText;
    console.log(str);
    var box = document.getElementById("formatter");
    // var cursorPosition = document.getElementById('formatter').prop("selectionStart")
    // var cursorPosition = getCursorPos(document.getElementById('formatter'))
    insertAtCursor(box, str + " ");
  }

  // <Table.Cell id="hearth"
  // onClick={(e)=> this.handleClick(e.target.id)}
  // >🤩</Table.Cell>

  render() {
    return (
      <Table celled>
        <Table.Body>
          <Table.Row>
            <TableCell
              id="uno"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji-punto">•</span>
            </TableCell>
            <TableCell
              id="due"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">✅</span>
            </TableCell>
            <TableCell
              id="tre"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">⚠</span>
            </TableCell>
          </Table.Row>
          <Table.Row>
            <TableCell
              id="quattro"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">➡</span>
            </TableCell>
            <TableCell
              id="cinque"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">⭕ </span>
            </TableCell>
            <TableCell
              id="sei"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">✴</span>
            </TableCell>
          </Table.Row>
          <Table.Row>
            <TableCell
              id="sette"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">🔴</span>
            </TableCell>
            <TableCell
              id="otto"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">▪</span>
            </TableCell>
            <TableCell
              id="nove"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">▶</span>
            </TableCell>
          </Table.Row>

          <Table.Row>
            <TableCell
              id="ten"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji-punto">◼</span>
            </TableCell>
            <TableCell
              id="eleven"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">🔘</span>
            </TableCell>
            <TableCell
              id="twelve"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji"> ★</span>
            </TableCell>
          </Table.Row>
          <Table.Row>
            <TableCell
              id="thirteen"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji"> ❁</span>
            </TableCell>
            <TableCell
              id="fourteen"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">🔷</span>
            </TableCell>
            <TableCell
              id="fifteen"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">⭐</span>
            </TableCell>
          </Table.Row>
          <Table.Row>
            <TableCell
              id="sixteen"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji"> ♕</span>
            </TableCell>
            <TableCell
              id="seventeen"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">⚙</span>
            </TableCell>
            <TableCell
              id="eighteen"
              className="emoji"
              handleClick={this.handleClick}
            >
              <span className="emoji">✉</span>
            </TableCell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

const Instructions = ({ language }) => (
  <div className="instructions-section" style={{marginTop: 25, marginBottom: 20, alignContent: "start"}}>
    {/*<h4>{contents[language].instructions.title}</h4>*/}
    <Step.Group size={"tiny"} className="steps">
      <Step>
        <Step.Content>
          <Step.Title>
            1) {contents[language].instructions.one.title}
          </Step.Title>
          {contents[language].instructions.one.subtitle()}
        </Step.Content>
      </Step>
      <Step>
        <Step.Content>
          <Step.Title>
            2) {contents[language].instructions.two.title}
          </Step.Title>
          {contents[language].instructions.two.subtitle()}
        </Step.Content>
      </Step>
      <Step>
        <Step.Content>
          <Step.Title>
            3) {contents[language].instructions.three.title}
          </Step.Title>
          {contents[language].instructions.three.subtitle()}
        </Step.Content>
      </Step>
    </Step.Group>
  </div>
);

const Newsletter = ({ language }) => (
	<Segment
    inverted
    vertical
	style={{ padding: "3em 0em", textAlign: "left" }}
	className="newsletter-section"
  >
    <Container>
	<div >
	  <h4>{contents[language].newsletter.description}</h4>
	  <Image
          src="/images/TRUST-able_Newsletter Ad_Logo_2022_InboxReads.png"
          style={{
            width: "400px",
			height: "250px",
			marginBottom: "20px",
			alignItems: "center"
          }}
        />
	</div>
	<a

          className="ui primary button block"
          href={language == "italian" ? "https://robingooditalia.substack.com" : "https://robingood.substack.com"}
        >
          {contents[language].newsletterButton}
        </a>
	</Container>
	
	</Segment>
  );
  

class HomepageHeading extends Component {
  constructor() {
    super();
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.updateCharacterCount = this.updateCharacterCount.bind(this);
    this.state = { characterCount: 0 };
  }

  updateCharacterCount() {
    var str = document.getElementById("formatter").value;
    this.characterCount = str.length;
    console.log("updated character cnt");
    console.log(this.characterCount);
    this.setState({ characterCount: str.length });
  }

  copyTextToClipboard(message) {
    const el = document.createElement("textarea");
    el.value = message;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  linkify(inputText) {
    //URLs starting with http://, https://, or ftp://
    var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    var replacedText = inputText.replace(replacePattern1, "$1 ");

    //URLs starting with www. (without // before it, or it'd re-link the ones done above)
    var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    var replacedText = replacedText.replace(replacePattern2, "http://$2 ");

    return replacedText;
  }

  copyToClipboard(el) {
    var str = document.getElementById("formatter").value;

    str = str.replace(/(?:\r\n|\r|\n)/g, "\u2063\n");

    // str=str.replace(/\u2063/g,'')

    str = this.linkify(str);

    document.getElementById("formatter").value = str;
    // resolve the element
    el = typeof el === "string" ? document.querySelector(el) : el;
    // handle iOS as a special case
    // if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // 	// save current contentEditable/readOnly status
    // 	var editable = el.contentEditable;
    // 	var readOnly = el.readOnly;
    // 	// convert to editable with readonly to stop iOS keyboard opening
    // 	el.contentEditable = true;
    // 	el.readOnly = true;
    // 	// create a selectable range
    // 	var range = document.createRange();
    // 	range.selectNodeContents(el);
    // 	// select the range
    // 	var selection = window.getSelection();
    // 	selection.removeAllRanges();
    // 	selection.addRange(range);
    // 	el.setSelectionRange(0, 999999);
    // 	// restore contentEditable/readOnly to original state
    // 	el.contentEditable = editable;
    // 	el.readOnly = readOnly;
    // } else {
    // 	el.select();
    // }

    // // execute copy command
    // document.execCommand('copy');

    this.copyTextToClipboard(str);

    Alert.success(
      "Il tuo testo *formattato* è ora pronto per essere incollato " +
        "nel tuo social preferito.",
      {
        effect: "flip",
        beep: false,
        timeout: 3000,
        // offset: 100
      }
    );
  }

  render() {
    const { language } = this.props;
    const lang = contents[language];

    return (
      <Container text textAlign="left">
        <Alert position="top" />
        <br />
        <Header
          as="h6"
          inverted
          style={{
            fontSize: this.props.mobile ? "0.7em" : "0.9em",
            fontWeight: "normal",
            marginTop: this.props.mobile ? "0em" : "0em",
          }}
        >
          <a href={"https://robingood.substack.com"} target="_BLANK">
            <Image
              src="/images/TRUSTable_text-only_logo.png"
              style={{
                width: "120px",
                height: "40px",
                display: "inline",
              }}
            />
          </a>
        </Header>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
			justifyContent: "space-between",
			//backgroundColor: "green"
          }}
        >
			<Header
            as="h1"
            content="Social Media"
            inverted
            style={{
              fontSize: this.props.mobile ? "1.5em" : "2em",
              fontWeight: "normal",
			  marginTop: this.props.mobile ? '0em' : '0em',
			}}>
				Social Media <a style={{
              fontWeight: "bold", fontSize: this.props.mobile ? "1em" : "1em", color: "white"}}>Formatter</a>
			  </Header>
          
          <Header
            as="h6"
            inverted
            style={{
              fontSize: this.props.mobile ? "0.7em" : "0.9em",
              fontWeight: "normal",
              marginTop: this.props.mobile ? "0em" : "0em",
              marginLeft: this.props.mobile ? "0em" : "0em",
            }}
          >
            by{" "}
            <a href={"https://robingood.substack.com"} target="_BLANK">
              {" "}
              Robin Good{" "}
              <Image
                circular
                src="/images/robin-icon.png"
                style={{
                  width: "36px",
                  height: "36px",
                  display: "inline",
                }}
              />
            </a>
          </Header>
        </div>
        <Message style={{padding: 0, margin: 0, marginBottom: "1em"}} color="black">{lang.description()}</Message>

        <Grid style={{marginBottom: "-1.4rem"}}>
          <Grid.Column width={12} style={{ paddingBottom: 0}}>
            <Form
              onSubmit={() => {
                this.copyToClipboard(".js-copytextarea");
              }}
            >
              <TextArea
                placeholder=""
                id="formatter"
                className="js-copytextarea textemoji"
                style={{ minHeight: 150 }}
                onChange={() => this.updateCharacterCount()}
              />
              <br />
              <span style={{ float: "right" }}>
                {" "}
                {this.state.characterCount + " characters"}
              </span>
              <br />
            </Form>

            <br />
          </Grid.Column>
          <Grid.Column width={4} className="emoji-table">
            <EmoticonsGrid />
          </Grid.Column>
        </Grid>
        <button
          className="ui primary button block"
          onClick={() => this.copyToClipboard(".js-copytextarea")}
        >
          {lang.button}
        </button>

      </Container>
    );
  }
}

const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const Footer = ({ language }) => (
  <Segment
    inverted
    vertical
    style={{ padding: "3em 0em" }}
    className="footer-section"
  >
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column>
            <Header inverted as="h5" content={contents[language].footerNote} />
            <React.Fragment>
              Made with love for those who support me on {"   "}
              <a href={PATREON_LINK} target="_BLANK">
                <Image
                  src="/images/patreon.jpeg"
                  className="pateron-img"
                  style={{ display: "inline" }}
                />
              </a>
              {"   "}
              Technical Implementation by{" "}
              <a href="http://devzstudio.com" target="_BLANK">
                {" "}
                Jithin{" "}
              </a>{" "}
            </React.Fragment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
);

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children, language } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ padding: "1em 0em" }}
            className="custom-segment"
            vertical
          >
            <HomepageHeading language={language} />
            <Instructions language={language} />
			<Newsletter language={language}/>
            {/*<Footer language={language} />*/}
          </Segment>
        </Visibility>
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children, language } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{ padding: "1em 0em" }}
            className="custom-segment"
            vertical
          >
            <Container />
            <HomepageHeading mobile language={language} />
            <Instructions language={language} />
			<Newsletter language={language}/>
            {/*<Footer language={language} />*/}
          </Segment>
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children, language }) => (
  <div>
    <DesktopContainer language={language}>{children}</DesktopContainer>
    <MobileContainer language={language}>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const HomepageLayout = (props) => (
  <ResponsiveContainer language={props.language} />
);
export default HomepageLayout;

// export default App
